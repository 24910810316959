class ReadView {
  #allArticles = document.querySelectorAll(".article");
  #wheel = document.querySelector(".wheel-of-fortune");
  #spinBtn = document.querySelector(".spin");
  #animation = [];
  #previousEndDegree = [0];

  spinWheel() {
    if (!this.#spinBtn) return;
    this.#spinBtn.addEventListener("click", () => {
      const randomAdditionalDegrees = Math.random() * 180 + 100;
      const newEndDegree = this.#previousEndDegree[0] + randomAdditionalDegrees;

      this.#animation[0] = this.#wheel.animate(
        [
          { transform: `rotate(${this.#previousEndDegree[0]}deg)` },
          { transform: `rotate(${newEndDegree}deg)` },
        ],
        {
          duration: 3000,
          direction: "normal",
          easing: "cubic-bezier(0.440, -0.205, 0.000, 1.130)",
          fill: "forwards",
          iterations: 1,
        }
      );

      this.#previousEndDegree[0] = newEndDegree;
    });
  }

  _handleIntersection(entries) {
    if (!entries[0].isIntersecting) return;

    const id = entries[0].target.dataset.article;

    document.querySelectorAll(".category--item").forEach((cat) => {
      cat.classList.remove("highlight");
    });

    document.querySelector(`#${id}`).classList.add("highlight");
  }

  observeTopScroll() {
    const observer = new IntersectionObserver(this._handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    });

    this.#allArticles.forEach((article) => {
      observer.observe(article);
    });
  }
}

export default new ReadView();
