class ArticleView {
  #sectionArticle = document.querySelector(".section-article");

  detectPage() {
    const id = location.href?.split("#")[1];

    this._insertContent(id);
  }

  _insertContent(id) {
    this.#sectionArticle.innerHTML = "";
    this.#sectionArticle.innerHTML = `${id}`;
  }
}
export default new ArticleView();
